import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { TaskWithOrganization } from "@dewo/app/graphql/types";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { useNavigateToTaskFn } from "@dewo/app/util/navigation";
import { Skeleton, Table, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { SortOrder, TablePaginationConfig } from "antd/lib/table/interface";
import _ from "lodash";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useEffect, useMemo, useState } from "react";
import { TaskTagsRow } from "../../task/card/TaskTagsRow";
import { calculateTaskRewardAsUSD } from "../../task/hooks";
import { TaskViewLayoutData } from "../../task/views/hooks";
import { TaskRewardSummary } from "./TaskRewardSummary";

interface Props {
  data: TaskViewLayoutData;
  pageSize?: number;
}

export const TaskDiscoveryTable: FC<Props> = ({ data, pageSize = 10 }) => {
  const router = useRouter();
  const navigateToTask = useNavigateToTaskFn();
  const screens = useBreakpoint();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize,
  });
  const rows = useMemo(
    () =>
      _.range((data.hasMore ? data.total : data.tasks?.length) ?? 0).map(
        (index) => data.tasks?.[index] as TaskWithOrganization
      ),
    [data.hasMore, data.total, data.tasks]
  );

  const isLastTaskLoaded = useMemo(() => {
    if (!pagination?.current || !pagination?.pageSize) return true;
    const lastTaskIndex = pagination.current * pagination.pageSize - 1;
    return !!data.tasks?.[lastTaskIndex];
  }, [pagination, data.tasks]);
  useEffect(() => {
    if (!data.loading && !isLastTaskLoaded) {
      data.fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.loading, isLastTaskLoaded]);

  return (
    <Table
      dataSource={rows}
      pagination={{
        hideOnSinglePage: true,
        onChange: (page) => router.push({ query: { ...router.query, page } }),
        current: Number(router.query.page) || 1,
      }}
      size="small"
      showHeader={false}
      tableLayout="fixed"
      rowClassName="hover:cursor-pointer"
      className="dewo-discovery-table"
      rowKey="id"
      onChange={setPagination}
      onRow={(t) => ({
        onClick: (e) => navigateToTask(t.id, e.metaKey),
      })}
      columns={[
        {
          key: "organization",
          width: 64 + 8 * 2,
          render: (_: unknown, task: TaskWithOrganization | undefined) =>
            !!task ? (
              <Link href={task.workspace.organization.permalink}>
                <a onClick={stopPropagation}>
                  <OrganizationAvatar
                    organization={task.workspace.organization}
                    size={64}
                    tooltip={{ title: "View DAO profile" }}
                  />
                </a>
              </Link>
            ) : (
              <Skeleton.Avatar active size={64} />
            ),
        },
        {
          key: "overview",
          render: (__: unknown, task: TaskWithOrganization | undefined) =>
            !!task ? (
              <>
                <Typography.Paragraph
                  strong
                  ellipsis={{ rows: 1 }}
                  style={{ margin: 0 }}
                >
                  {task.name}
                </Typography.Paragraph>
                <Typography.Paragraph
                  type="secondary"
                  style={{ marginBottom: 8 }}
                  className="ant-typography-caption"
                >
                  {moment(task.createdAt).fromNow()} by{" "}
                  {task.workspace.organization.name}
                </Typography.Paragraph>
                <TaskTagsRow task={task} />
                {!screens.lg && (
                  <TaskRewardSummary
                    task={task}
                    direction="horizontal"
                    style={{ marginTop: 8 }}
                  />
                )}
              </>
            ) : (
              <Skeleton
                active
                title={false}
                style={{ display: "flex" }}
                paragraph={{ rows: 2, style: { margin: 0 } }}
              />
            ),
        },
        ...(screens.lg
          ? [
              {
                title: "Reward",
                dataIndex: "reward",
                width: 164,
                render: (_: unknown, task: TaskWithOrganization | undefined) =>
                  !!screens.lg &&
                  !!task && (
                    <TaskRewardSummary task={task} direction="vertical" />
                  ),
                sorter: (a: TaskWithOrganization, b: TaskWithOrganization) =>
                  _.sum(a.rewards.map(calculateTaskRewardAsUSD)) -
                  _.sum(b.rewards.map(calculateTaskRewardAsUSD)),
                sortDirections: ["descend"] as SortOrder[],
              },
            ]
          : []),
      ]}
    />
  );
};
