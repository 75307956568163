import { TaskOpenToBidsTag } from "@dewo/app/components/task/TaskOpenToBidsTag";
import { Task } from "@dewo/app/graphql/types";
import { Row, Typography } from "antd";
import React, { CSSProperties, FC } from "react";
import { formatTaskRewardAsUSD } from "../../task/hooks";
import {
  MultipleTaskRewardsTag,
  TaskRewardTag,
} from "../../task/reward/TaskRewardTag";

interface Props {
  task: Task;
  style?: CSSProperties;
  direction: "vertical" | "horizontal";
}

export const TaskRewardSummary: FC<Props> = ({ task, style, direction }) => {
  const taskRewardAsUsd = task.rewards
    .filter((reward) => !!reward.token.usdPrice && !reward.peggedToUsd)
    .map(formatTaskRewardAsUSD)
    .join(" + ");

  if (!!task.rewards.length) {
    if (direction === "vertical") {
      return (
        <>
          <Row justify="center" style={style}>
            <MultipleTaskRewardsTag rewards={task.rewards} />
          </Row>
          {!!taskRewardAsUsd && (
            <Typography.Paragraph
              type="secondary"
              className="ant-typography-caption"
              style={{ textAlign: "center", margin: 0 }}
            >
              {taskRewardAsUsd}
            </Typography.Paragraph>
          )}
        </>
      );
    } else {
      return (
        <Row align="middle" style={style}>
          {task.rewards.map((r) => (
            <TaskRewardTag key={r.id} reward={r} />
          ))}
          {!!taskRewardAsUsd && (
            <Typography.Paragraph
              type="secondary"
              className="ant-typography-caption"
              style={{ textAlign: "center", margin: 0 }}
            >
              {taskRewardAsUsd}
            </Typography.Paragraph>
          )}
        </Row>
      );
    }
  }

  if (task.openToBids) {
    return (
      <Row justify={direction === "vertical" ? "center" : undefined}>
        <TaskOpenToBidsTag style={{ margin: 0 }} />
      </Row>
    );
  }

  return null;
};
